import Color from "color"

// Colors
const primary = "#f37990"
const secondary = "#d978bf"
const black = "#000"
const background = Color(secondary)
  .lighten(0.4)
  .string()
const backgroundStart = background
const backgroundStop = Color(primary)
  .lighten(0.9)
  .string()
const text = "#3d5fa6"

export {
  black,
  primary,
  secondary,
  background,
  backgroundStart,
  backgroundStop,
  text,
}
